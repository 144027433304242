
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        























































.partner-hero {
  position: relative;
  margin-top: $spacing * 8;

  &__headline {
    @extend %text-uppercase;

    display: flex;
    align-items: center;

    &__picture {
      max-height: 60px;
      margin-right: 1em;
    }

    &__label {
      position: relative;
      display: inline-block;
      padding: 8px 14px;
      color: $white;
      font-size: 1.2rem;
      font-weight: 900;
      line-height: 1;
      text-transform: uppercase;
      white-space: nowrap;
      background-color: $c-orange;
      border-radius: 5px;
      margin-bottom: 1px;
    }
  }

  &__title {
    margin-top: $spacing * 2;

    @include mq(m) {
      width: inner-col(16, 14);
    }
  }

  &__visuals {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 18rem;

    @include mq(m) {
      right: 10rem;
      width: inner-col(16, 3);
      max-width: 60rem;
      height: 100%;
      padding: 0;
    }
  }
}

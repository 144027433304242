
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































.partners-cards {
  &__title {
    text-align: center;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 6rem;
    justify-content: center;
    margin: 3rem 2rem;

    @include mq(s) {
      grid-template-columns: 1fr 1fr;
    }

    @include mq(xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        











































.partner-card {
  text-align: center;

  &__label {
    position: relative;
    display: inline-block;
    padding: 8px 14px;
    color: $white;
    font-size: 1.2rem;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: $c-mandarine;
    border-radius: 5px 5px 0 0;
    margin-bottom: 1px;
  }

  &__inner {
    @extend %card-shell;
    justify-content: center;
    align-items: center;
    padding: 3rem;

    @include mq(xl) {
      padding: 4rem;
    }
  }

  &__picture {
    max-width: 200px;
  }

  &__link {
    @extend %link--pink;

    background: none;
    text-decoration: underline;
  }
}

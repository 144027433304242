
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



































































.partner-aside {
  position: sticky;
  top: 2rem;
  font-family: $ff-default;
  font-weight: 400;

  &__back {
    display: flex;
    align-items: center;
    text-decoration: none;

    .icon {
      transform: rotate(90deg);
      margin-right: 1rem;
    }
  }

  &__name {
    color: $c-nevada;
    text-transform: uppercase;
    margin-top: 70px;
  }

  &__link {
    display: flex;
    font-weight: bold;
  }

  &__button {
    padding: 1rem 2rem;
    margin-top: 2rem;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: $spacing * 2;
    padding-top: $spacing * 2;
    border-top: 1px solid $c-gray-lightest;
  }

  &__tag {
    @extend %fw-bold;
    @extend %text-uppercase;

    padding: $spacing / 2 $spacing;
    color: $c-gray;
    font-family: $ff-alt;
    font-size: 1.2rem;
    text-decoration: none;
    border: 1px solid $c-gray-lightest;
    border-radius: 0.5rem;
  }
}
